import React, {useEffect, useState, useRef} from "react";
import { useNavigate } from 'react-router-dom';

//API
import { Notify } from "../Utils/ApiRequests.jsx";

// components
import PublicWorkoutSection from "./Workout/Public/PublicWorkoutSection.jsx";
import WorkoutDetails from "./Workout/WorkoutDetails.jsx";
import Footer from "../components/Footer.jsx";
import HeaderText from "./HeaderText.jsx";
//Style
import { HomeContentContainer, MainContentContainer, TextContentContainer, TextContainer, ColumnContainer, FadeInContainer } from "../components/style/Container.styled.jsx";
import { Row, Column } from "../components/style/Row.styled.jsx";
import { MainContainer, MainSection, TopSquare} from "../components/style/Section.styled.jsx";
//image
import gym from '../components/style/images/gym.jpg'

//utils
import { GetPublicData } from "../Utils/ApiRequests.jsx";
import { useData, useWebSocket } from "../Utils/UseContext.jsx";


export const ContentContainer = ({title, content, width, justify, align, bg, titleColor, nav, Style}) => {

    return(
        <MainContentContainer bg={bg} width={width} justify={justify} align={align}>
            <Column>
                <Row padding={'none'} color={titleColor} onClick={nav}>
                    <h3 style={Style}>{title}</h3>
                </Row>
                {content}
            </Column>
        </MainContentContainer>
    )
}


const Main = ({onInstallClick, language, theme}) => {
    const navigate = useNavigate();
    const {setPublicData} = useData();

    const ws = useWebSocket();

    useEffect(() => {
      if (ws) {
        GetPublicData({setPublicData, ws});
      }
    }, [ws]);
    
    // Content to this should come frome DB, now it is hard coded
    const newsContent = (news) => {
        return(
            <HomeContentContainer>
                <TextContentContainer>
                    {news}
                </TextContentContainer>
            </HomeContentContainer>
        )
    }

    return(
        <>
            <MainSection >
                <Row margin={'30px auto'} justify_mobile={'center'} margin_mobile={'30px auto'}>
                    <HeaderText theme={theme}/>
                </Row>
                <TopSquare image={gym}/>
            </MainSection>
            <MainContainer>
                <Row margin={'10px auto'}>
                    <ContentContainer title={'News'} content={newsContent('We will be releasing new features soon!')} bg={'transparent'}/>
                </Row>
                <Row margin={'20px auto'} justify={'end'}>
                    <WorkoutDetails theme={theme}/>
                </Row>
                <Row margin={'20px auto'}>
                    <ContentContainer title={'Public workout'} content={<PublicWorkoutSection theme={theme}/>} nav={() => navigate('/workout/public')} bg={'transparent'} Style={{cursor:'pointer'}}/>
                </Row>
            </MainContainer>
            <Footer language={language} onInstallClick={onInstallClick}/>
        </>
    )
}

export default Main