import React, {useEffect, useState} from "react";
//API
import { GetCompletedWorkout, GetWorkoutEvents } from "../../Utils/ApiRequests.jsx";
import { useAuth } from "../../Utils/UseContext.jsx";
//components
import { ContentContainer } from "../Main.jsx";
//utils
import { DateToString, TimeToString, MillisToMinutes, findNextEvent, GetCookieData } from "../../Utils/Utils.jsx";
//Style
import { HomeContentContainer } from "../../components/style/Container.styled.jsx";
import { Row } from "../../components/style/Row.styled.jsx";
import { ContentColumn } from "../../components/style/Home.style.jsx";



const WorkoutDetailsSection = ({sectionName, title, date, timeDetails}) => {
    return (
    <Row padding={'10px 0px 10px 0px'}>
        <ContentColumn style={{width:'100%'}}>
            <Row padding={'none'}>
                <ContentColumn>
                    <p>{sectionName}</p>
                    <p>{title}</p>
                </ContentColumn>
                <ContentColumn>
                    <p>{date}</p>
                    <p>{timeDetails}</p>
                </ContentColumn>
            </Row>
        </ContentColumn>
    </Row>
    )
}

const WorkoutDetails = ({theme}) => {
    const { state } = useAuth();
    const isAuthenticated = state?.token;
    // const {publicData, setPublicData} = useData();
    const [completedData, setCompletedData] = useState(null);
    const [events, setEvents] = useState(null);

    //Latest
    const latestCompleted = completedData?.completed?.completed[0];
    const latestDate = completedData ? DateToString(new Date(latestCompleted?.date)) : "";
    const title = completedData ? completedData?.latest_name : "";
    const duration = completedData ? MillisToMinutes(latestCompleted?.duration) : "";

    //Upcoming
    const nextEvent = events && (findNextEvent(events));
    const nextDate = events && (DateToString(new Date(nextEvent?.date_time)));
    const nextTime = events && (TimeToString(new Date(nextEvent?.date_time)));
    const name = nextEvent?.name_of_exercise;
    const capitalizedName = name?.charAt(0).toUpperCase() + name?.slice(1);

    
    useEffect(() => {

       if(isAuthenticated){
            getCompleted();
            fetchEvents();
       } 
    },[])

    const getCompleted = async () => {
        const data = await GetCompletedWorkout()
        setCompletedData(data);
    }

    const fetchEvents = async () => {
        // Hae eventit tietokannasta
         const fetchedEvents = await GetWorkoutEvents(); // Oikea endpoint ja hakulogiikka
         setEvents(fetchedEvents);
      };

    return(
        <>
        {isAuthenticated && (
            <ContentContainer title={'My'} width={'50vw'} align={'flex-end'}
                content={
                    <HomeContentContainer>
                        {completedData?.completed?.completed.length > 0 ?
                            <WorkoutDetailsSection sectionName={'Previous'} title={title} date={latestDate} timeDetails={`kesto: ${Math.round(duration)} min`}/>
                            :
                            <p>You have not completed the exercises yet</p>
                        }
                        <div style={{border: `solid 1px ${theme.colors.border}`}}></div>
                        {nextEvent ?
                            <WorkoutDetailsSection sectionName={'Next'} title={capitalizedName} date={nextDate} timeDetails={name !== 'rest' && (`Starts at ${nextTime}`)}/>
                            :
                            <p>You have no planned workouts</p>
                        }
                    </HomeContentContainer>
                } 
            />
        )}
        </>
    )
}


export default WorkoutDetails;