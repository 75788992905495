import React from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth} from "../Utils/UseContext.jsx";
import { NavBar } from "./style/NavBar.styled.jsx";
//icon
import { CgProfile } from "react-icons/cg";
//style
import { TransparentButton } from "./style/Buttons.styled.jsx";
import { Row } from "./style/Row.styled.jsx";
//button
import { CustomButton } from "./Buttons.jsx";


import '../components/style/Styles.css'

export const ProfileIcon = () => {
    const navigate = useNavigate();
    const {state} = useAuth();
    const show = state.isAuthenticated;

    // if(!show) return;

    return(
        <>
            {show && (
                <CgProfile size={30} onClick={() => navigate('/profile')} style={{cursor:'pointer', margin:10}}/>
            )}
        </>
    )
}

export const Nav = () => {
    const {state} = useAuth();
    const showNav = state.isAuthenticated;


    return(
        showNav && (
        <NavBar>
            <Link to="/home" className="nav-link">Home</Link>
            <Link to="/workout/my" className="nav-link">Workout</Link>
            <Link to="/calendar" className="nav-link">Calendar</Link>
            {/* <Link to="/profile" className="nav-link">Profile</Link> */}
            {/* <button onClick={() => navTo('profile')}>LINKKI</button> */}
        </NavBar>
        )
    )
}

export const WorkoutNav = ({}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = useAuth();
    const isAuthenticated = state.isAuthenticated;
    const isActive = location.pathname === '/workout/my' ? true : false;

    if(!isAuthenticated) return;

    return(
        <Row margin={'0 0 10px 0'} maxwidth={'400px'}>
            <TransparentButton width={'100px'} height={'32px'} isactive={isActive} onClick={() => navigate('/workout/my')}>
                My
            </TransparentButton>
            <TransparentButton width={'100px'} height={'32px'} isactive={!isActive} onClick={() => navigate('/workout/public')}>
                Public
            </TransparentButton>
        </Row>
    )
}

export const SubWorkoutNav = ({activeData, setActiveData}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = useAuth();
    const isAuthenticated = state.isAuthenticated;

    if(!isAuthenticated) return;

    if(!activeData){
        activeData = 'all';
    }

    const handleIsActive = (key) => {
        setActiveData(key)
    }

    return(
        <Row margin={'0 0 10px 0'} justify={'space-between'}>
            <Row padding={'0'} width={'fit-content'}>
                <TransparentButton height={'20px'} isactive={activeData === 'all'} style={{fontSize: 12}} onClick={() => handleIsActive('all')}>
                    All
                </TransparentButton>
                <TransparentButton height={'20px'} isactive={activeData === 'training_program'} style={{fontSize: 12}} onClick={() => handleIsActive('training_program')}>
                    Training programs
                </TransparentButton>
                <TransparentButton height={'20px'} isactive={activeData === 'training_plan'} style={{fontSize: 12}} onClick={() => handleIsActive('training_plan')}>
                    Training plans
                </TransparentButton>
                <TransparentButton height={'20px'} isactive={activeData === 'video'} style={{fontSize: 12, display: 'none'}} onClick={() => handleIsActive('video')}>
                    Videos
                </TransparentButton>
            </Row>
            <Row padding={'0'} width={'fit-content'}>
                <TransparentButton height={'20px'} isactive={true} style={{fontSize: 12, cursor:'pointer', display: 'none'}} onClick={() => console.log("sort")}>
                    Sort
                </TransparentButton>
                <TransparentButton height={'20px'} isactive={true} style={{fontSize: 12, cursor:'pointer', display: 'none'}} onClick={() => console.log("filter")}>
                    Filters
                </TransparentButton>
            </Row>

        </Row>
    )
}
