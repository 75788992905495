import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import Calendar from 'react-calendar';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";


export const CustomDatepicker = styled(DatePicker)`
  font-size: ${props => props.fontSize || '15px'};
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};
`;

export const CustomCalendar = styled(Calendar)`
    max-width: 1000px; /* Adjust the maximum width of the calendar */
    margin: 2em auto; /* Center the calendar horizontally */
    padding: 10px;
    font-family: Arial, sans-serif; /* Use a sans-serif font */
    // background: ${({theme}) => theme.colors.textBg};
    border: 1px solid ${({theme}) => theme.calendarColors.border};
    border-radius: 10px;


  .react-calendar__navigation {
    background-color: ${({theme}) => theme.calendarColors.tile};
    display: flex;
  }
  
  button {
    background-color: transparent;/* Set background color */
    color: ${({theme}) => theme.colors.text}; /* Set text color */
    border: none; /* Remove border */
    padding: 10px 10px; /* Add padding */
    margin: 0 5px; /* Add margin */
    border-radius: 4px; /* Add border radius */
    font-weight: 600;
    font-size: 15px;
    cursor: pointer; /* Add pointer cursor */
    transition: background-color 0.3s ease; /* Add transition for smooth hover effect */
    text-transform: uppercase;
  }

  button:hover{
    background-color: ${({theme}) => theme.calendarColors.hover};
  }

  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%; 
  }

  .react-calendar__tile {
    padding: 10px; /* Add padding to each calendar tile */
    text-align: center; /* Center the text */
    border: 1px solid ${({theme}) => theme.calendarColors.border}; 
    border-radius: 5px;
    background-color: ${({theme}) => theme.calendarColors.tile};
  }

  .react-calendar__month-view__weekdays {
    display: flex; /* Use flexbox for weekdays */
    justify-content: space-around; /* Space evenly between weekdays */
  }
  
  .react-calendar__month-view__weekdays__weekday {
    display: flex;
    justify-content: center;
    font-weight: bold; /* Make weekdays bold */
    color: ${({theme}) => theme.colors.text}; /* Set weekday text color */
    text-transform: uppercase;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #ccc; /* Style days from neighboring months */
    background-color: transparent;
  }
  
  .react-calendar__tile--active {
    background-color: #00a2ff5e; /* Style active date */
    color: ${({theme}) => theme.colors.text}; /* Set active date text color */
  }
  
  .react-calendar__tile--now {
    color: #870101; /* Set current date text color */
    font-weight: 700;
    font-size: 20px;
    padding: 0;
  }

  .react-calendar__tile:hover{
    background-color: ${({theme}) => theme.calendarColors.hover};
  }

  @media (max-width: 768px) {
      max-width: 100%;
  }
`

export const CustomDateTimePicker = styled(DateTime)`

input{
  color: ${({theme}) => theme.colors.text};
}

.rdtPicker {
  background: ${({theme}) => theme.colors.body};
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.rdtPicker th, .rdtPicker td {
  color: ${({theme}) => theme.colors.text};
}

.rdtPicker .rdtTimeToggle, .rdtPicker .rdtSwitch {
  // background-color: red;
  color: ${({theme}) => theme.colors.text};
}

.rdtPicker .rdtActive {
  border-radius: 10px;
  background-color: rgb(146, 146, 146, 0.6);
  color: ${({theme}) => theme.colors.text};
}

.rdtPicker td:hover {
  border-radius: 10px;
  background-color: rgb(146, 146, 146, 0.6);
}

.rdtPicker .rdtToday {
  border: 1px solid black;
  border-radius: 10px;
  background-color: rgb(146, 146, 146);
  color: ${({theme}) => theme.colors.text};
}

`;