import React, {useState, useEffect} from "react";
import {useLocation, useNavigate } from 'react-router-dom';
//data
import { useData} from "../../../Utils/UseContext.jsx";
//utils
import { DateNow, DateToString } from "../../../Utils/Utils.jsx";
//API
import { InsertTrainingPlansEvent } from "../../../Utils/ApiRequests.jsx";
//Style
import { Column, Row, RowColumn } from "../../../components/style/Row.styled.jsx";
import { Container, StyledPlannerContainer} from "../../../components/style/Container.styled.jsx";
//components
import { WorkoutMedia } from "../WorkoutMedia.jsx";
//Icons
import { IoIosArrowDown, IoIosArrowBack} from "react-icons/io";
import { CustomButton } from "../../../components/Buttons.jsx";
import { StyledSubmitButton } from "../../../components/style/Buttons.styled.jsx";




const MyTrainingPlan = ({theme}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {id} = location.state || {};

    const {exercisesData, personalTrainingPlan} = useData();
    const [days, setDays] = useState([]);
    const [description, setDescription] = useState(""); // Asetetaan oletukseksi 0 päivää
    const [media, setMedia] = useState(null); // Harjoitukset tietokannasta
    const [isModalOpen, setIsModalOpen] = useState();
    const [copyInterval, setCopyInterval] = useState(0);
    const [trainingPlan, setTrainingPlan] = useState();
    const [count, setCount] = useState({workout: 0, rest: 0});
    const [name, setname] = useState("");
    const [infoOpen, setInfoOpen] = useState(false);
    const [isPlanActive, setIsPlanActive] = useState(false);

    const deviceScreenSize = {
        width: window.screen.width,
        height: window.screen.height,
      };

    // console.log("data: ", exercisesData)
    // Esimerkki harjoituksista, jotka voisivat tulla tietokannasta
    useEffect(() => {
        // const data = exercisesData?.data;
        // setExercises(data);
        // console.log("Raw session data:", rawSession);

        try {
            
            const findItem = personalTrainingPlan?.trainingplan.find((item) => item.workout_id === id)

            if(findItem){
                setTrainingPlan(findItem)
                setDays(findItem?.training_plan_content);
                setDescription(findItem?.description);
                setname(findItem?.workout_name);
                setMedia(findItem?.file);
                setIsPlanActive(DateNow() < new Date(findItem?.expired_time));
                if(isPlanActive){
                    // reverseDays(findItem?.expired_time)
                }
            }

        } catch (error) {
          console.error("Failed to parse sessionStorage data:", error);
        }
    }, [id]);

    useEffect(() => {

      const workoutCount = days.filter((day) => day.status === 'workout').length;
      const restCount = days.filter((day) => day.status === 'rest').length;

      setCount({workout: workoutCount, rest: restCount});
    },[days])

    const addDays = (date, days) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days); // Lisää päivät
        return newDate;
    };

    // const reverseDays = (date) => {
    //     const endDate = new Date(date); // Päättymispäivämäärä
    //     const totalDays = trainingPlan?.training_plan_content.length; // Harjoitusten määrä
    //     const list = trainingPlan?.training_plan_content;
        
    //     list.forEach((item, index) => {
    //         // Laske päivämäärä taaksepäin indeksin perusteella
    //         const daysFromEnd = totalDays - 1 - index; 
    //         const dateTime = new Date(endDate);
    //         dateTime.setDate(dateTime.getDate() - daysFromEnd);
        
    //         item.date_time = dateTime.toISOString(); // Aseta `date_time`-kenttä objektiin
        
    //         const idnum = Math.random() * 100;
    //         if (item.exercises.length > 0) {
    //             item.exercises.forEach((value, i) => {
    //                 value.part_of_workout_id = "plan-" + Math.floor(idnum) + String(index);
    //             });
    //         }
    //     });
        
    // }

    const startPlan = async () => {
        const now = DateNow();
        const list = trainingPlan?.training_plan_content;

        list.forEach((item, index) => {
            const dateTime = addDays(now, index); // Lisää indeksin mukainen määrä päiviä
            item.date_time = dateTime.toISOString(); // Lisää `date_time`-kenttä objektiin
            const idnum = Math.random() * 100;

            if(item.exercises.length > 0){
                item.exercises.forEach((value, i) => {
                    value.part_of_workout_id = "plan-" + Math.floor(idnum) + String(index);
                })
            }

        });

        // console.log("LIST: ", trainingPlan)
       await InsertTrainingPlansEvent(trainingPlan);

        setTrainingPlan({
            ...trainingPlan,
            training_plan_content: list
        });
    }

    const toDateString = (date) => {
        if(!date) return;
        const day = new Date(date).getDate();
        const month = new Date(date).getMonth() + 1;
        const year = new Date(date).getFullYear();

        return `${day}.${month}.${year}`
    }



    const DaySection = () => {
       return(
        <div style={{ display: 'flex', flexDirection: 'column', minWidth: '50%', maxHeight: '55vh', marginTop: '10px', paddingBottom: 55, overflow: 'auto'}}>
                <Row justify={'space-between'} align={'start'}>
                        <p>{count.workout} training days</p>
                        <p>{count.rest} rest days</p>
                </Row>
            <Row margin={'10px 0px 10px 0px'}>
                <p>Duration {days?.length} days</p>
            </Row>
          {days.map((day, index) => (
            <div key={index} style={{ border: `1px solid ${theme.colors.border}`, borderRadius: 5, maxHeight:'90px', padding: '10px', margin: '2px 0px 2px 0px' }}>
                <Row padding={'0px 38px 10px 0px'}>
                    <h4>Day {index + 1} - {day.status === 'rest' ? day.status : day?.part_of_workout_name}</h4>
                    {isPlanActive && (<p style={{fontSize: 10}}>Training day: {toDateString(day?.date_time)}</p>)}
                </Row>
                <Column style={{overflow: 'auto', maxHeight: '50px'}}>
                    {days[index]?.exercises.length > 0 && (
                        days[index]?.exercises.map((item, i) => (
                            <Row key={i} padding={'0px 38px 0px 0px'}>
                                <p key={i} style={{fontSize: 12}}>{item?.name_of_exercise}</p>
                                <div>
                                    <span> {item?.times} x {item?.amount}</span>
                                </div>
                            </Row>
                        ))
                    )}
                </Column>
            </div>
          ))}
        </div>
      )
    }

    const InfoSection = () => {

        return (
            <>
                {deviceScreenSize?.width < 601 && (
                    <Row justify={'flex-end'} align={'start'}>
                        <div></div>
                        <>
                        {infoOpen ? <IoIosArrowDown onClick={() => setInfoOpen(!infoOpen)}/> : <IoIosArrowBack onClick={() => setInfoOpen(!infoOpen)}/>}
                        </>
                    </Row>
                )}
            <Column width={'100%'} height={infoOpen && ('0px')} style={{overflow: 'hidden'}}>
                <Row justify={'flex-start'} align={'start'} >
                    <WorkoutMedia src={media} type="image" width={'100px'} height={'100px'}/>
                    <Column justify={'space-between'} height={'100px'} >
                        <h2 style={{marginLeft: 30}}>{name}</h2>
                        <CustomButton disabled={isPlanActive} button={StyledSubmitButton} label={'Start now'} style={{marginLeft: 30}} onClick={() => startPlan()}/>
                    </Column>
                </Row>
                <Row>
                    <Column height={'fit-content'} style={{margin: '20px 0px 20px 0px', maxHeight: 300, overflow: 'auto'}}>
                        <p>Description:</p>
                        <p style={{fontSize: 14}}>{description}</p>
                    </Column>
                </Row>
            </Column>
            </>
        )
    }

    return (
    <Container>
    <div style={{height: '76vh', width: '100%'}}>
        <h1 style={{textAlign: 'center'}}>Training plan</h1>
      <StyledPlannerContainer>
        <InfoSection />
        <DaySection />
      </StyledPlannerContainer>
     </div>
    </Container>
    );
}

export default MyTrainingPlan;