import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams} from 'react-router-dom';
// API
import { VerifyEmail, ReVerifyEmail, UpdateEmail, UpdatePassword } from '../Utils/ApiRequests';
//style
import { Container } from '../components/style/Container.styled';
import { Column } from '../components/style/Row.styled';

function EmailVerification(props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [verificationData, setVerificationData] = useState({email: null, code: null, meta: null});
  const [isVerified, setIsVerified] = useState();
  const [message, setMessage] = useState("");
  const [counter, setCounter] = useState(null);

  let title = "";
  let content = "";

  useEffect(() => {
    const code = searchParams.get('code');
    const email = searchParams.get('email');
    if (code && email) {
      // Aseta varmistuskoodi tilaan
      const verificationObj = {email: email, code: code}
      setVerificationData(verificationObj);
      // Voit tässä vaiheessa lähettää varmistuskoodin palvelimelle ja käsitellä varmistuksen
    }
  }, [searchParams]);

  useEffect(() => {
    let intervalId;
    if (counter > 0) {
      intervalId = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
    }else if(counter === 0){
        navigate('/')
    }

    // Poista ajastin, kun komponentti puretaan (unmounted)
     return () => {
      clearInterval(intervalId);
     };
  }, [counter]);

  switch (props.status) {
    case 'emailupdate':
      title = 'Verify your email';
      content = 'Verify your email by pressing the button below.'
      break;
    case 'register':
      title = 'Verify your email';
      content = 'Verify your email by pressing the button below.'
      break;
    case 'pwdupdate':
      title = 'Verify';
      content = 'Verify that you changed your password.'
      break;
    default:
      title='The Bridge of Khazad-Dum';
      content= 'You shall not pass!';
      break;
  }

  const handleVerification = async () => {
    let verificationResponse = null;

    switch (props.status) {
      case 'emailupdate':
        verificationResponse = await UpdateEmail(verificationData.email, verificationData.code)
        break;
      case 'register':
        verificationResponse = await VerifyEmail(verificationData.email, verificationData.code);
        break;
      case 'pwdupdate':
        verificationResponse = await UpdatePassword(verificationData.email, verificationData.code)
        break;
      default:
        verificationResponse = null;
        break;
    }
    

    //API kutsu tietokannasta väliaikainen verifiointi koodi ja email pari. 
    // jos täsmää jatka, muuten hylkää ja pyydä lähettämään uusi.
    if(!verificationResponse?.verified){
        setIsVerified(false)

    }else{
        setIsVerified(true);
        setCounter(10)
    }
    setMessage(verificationResponse?.message)
  };

  const handleReVerification = async () => {

    await ReVerifyEmail(verificationData?.email);
    setIsVerified(true);
    setCounter(10);

  }


  return (
    <Container>
      <Column>
      <h2>{title}</h2>
      <p>{content}</p>
      <button onClick={handleVerification}>Verify</button>
      <p>{message}</p>
      {isVerified && ( 
            <p>Siirrytään automaattisesti <span style={{cursor:'pointer'}} onClick={() => navigate('/')}>etusivulle</span> {counter} sekunnin kuluttua.</p>
      )}
      {isVerified === false && (
        <p style={{cursor:'pointer'}} onClick={handleReVerification}>Reorder the verification link again.</p>
      )}
      </Column>
    </Container>
  );
}

export default EmailVerification;
