import React, {useState, useEffect} from "react";
import { DateNow} from "../Utils/Utils.jsx";
//components
import { CustomButton, ExitButton } from "./Buttons.jsx";
//style
import { LABEL, StyledWorkoutInput } from "./style/CustomInput.styled.jsx";
import { Styled20radiusButton } from "./style/Buttons.styled.jsx";
import { ButtonContainer } from "./style/Container.styled.jsx";
import { Card } from "./style/Card.style.jsx";
import { CardListContainer } from "./style/List.styled.jsx";
//icons
import { MdOutlineStarOutline, MdOutlineStar } from "react-icons/md";




export function FlatList({data}){
    function ListItem({item}){
      return <li>{item}</li>
    }

    return (
      <ul>
        {data.map((item, index) => (
          <ListItem key={index} item={item} />
        ))}
      </ul>
    );

}

export function ScrollView({items, renderItem, onClick}) {

  const content = items.map((item, index) => (
    // <p key={index} style={{lineHeight: 0, fontSize: '13px'}}>{item}</p>
    <div onClick={() => onClick && (onClick(item))} key={index} style={{cursor: onClick && ('pointer')}}>
      {/* {item} */}
      {renderItem(item, index)}
    </div>
  ));

  return (
    <div style={{ overflow: 'auto', maxHeight: '90%', width: '100%'}}>
      {content}
    </div>
  );
}

export const CardList = ({children}) => {

  return (
    <CardListContainer>
        {children}
    </CardListContainer>
  );
}

export function Modal({ isOpen, children, onClose, bg }) {
    
    if (!isOpen) {
      return null;
    }
    
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: `${bg ? bg : 'rgba(0, 0, 0, 0.5)'}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}>
        {/*  */}
        <div style={{
          // background: `blue` ,
          // padding: 5,
          // borderRadius: 5,
          // width: '100%',
          // minHeight: '10em',
          // maxHeight: '40em'
        }}>
          <div style={{display: 'flex',justifyContent:'flex-end'}}>
            <ExitButton onClick={onClose} label={'X'} />
          </div>
          {children}
        </div>
      </div>
    );
  }

export const CustomTextInput = (props) => {

    const INPUT = props.input || 'input';
    const [value, setValue] = React.useState("");
    const [focused, setFocused] = React.useState({"": false})

    const maxLength = props.maxLength || 4;
    const val = props.val;
    const labelName = props.labelName;
    const id = props.id;
    const index = props.index;
    const partIds = props.partKey;
    const handleOnBlur = props.handleOnBlur;
    const type = props.type || 'text';

    const minWidth = props.minWidth || 10;
    const widthPerCharacter = 7;

    const inputWidth = minWidth + val?.length * widthPerCharacter;

    const handleOnFocus = (value, key) => {
        setFocused({[key]: true});
        setValue(value);
      }

    const handleOnChange = (text) => {
        setValue(text)
    }

    const checkIfNull = (val) => {
        return val !== "null" ? val : ''
    }

    return (
        <>
            {props.label && (
              <LABEL>{props.label}</LABEL>
            )}
            <INPUT
                type={type}
                width={inputWidth}
                maxLength={maxLength}
                onChange={e => handleOnChange(e.target.value)}
                onFocus={() => (handleOnFocus(checkIfNull(val), labelName))}
                onBlur={() => handleOnBlur(labelName, value, id, index)}
                value={focused[labelName] ? value : checkIfNull(val)}
                placeholder={props.placeholder}
            />
        </>
    )
  }

  export const CustomNumberInput = (props) => {

    const INPUT = props.input || 'input';
    const [value, setValue] = React.useState(0);
    const [focused, setFocused] = React.useState({"": false})

    const maxLength = props.maxLength || 4;
    const val = props.val;
    const labelName = props.labelName;
    const id = props.id;
    const index = props.index;

    const handleOnBlur = props.handleOnBlur;


    const minWidth = props.minWidth || 10;
    const widthPerCharacter = 7;

    const inputWidth = minWidth + val?.length * widthPerCharacter;

    const handleOnFocus = (value, key) => {
        setFocused({[key]: true});
        const val = parseInt(value)
        setValue(val);
      }

    const handleOnChange = (text) => {
        const value = parseInt(text)
        setValue(value)
    }

    const checkIfNull = (val) => {
        return val !== "null" ? val : 0
    }

    return (
        <>
            {props.label && (
              <LABEL>{props.label}</LABEL>
            )}
            <INPUT
                type={'number'}
                width={inputWidth}
                maxLength={maxLength}
                onChange={e => handleOnChange(e.target.value)}
                onFocus={() => (handleOnFocus(checkIfNull(val), labelName))}
                onBlur={() => handleOnBlur(labelName, value, id, index)}
                value={focused[labelName] ? value : checkIfNull(val)}
                placeholder={props.placeholder}
            />
        </>
    )
  }

export const NewWorkoutTextInput = (props) => {
  const [value, setValue] = React.useState("");

  const handleOnChange = (e) => {
      setValue(e.target.value)
  }

  const checkIfNull = (val) => {
      return val !== "null" ? val : ''
  }

  return (
      <>
          <StyledWorkoutInput
            type="text"
            placeholder="Legs..."
            value={value}
            onChange={handleOnChange}
          />
      </>
  )
}

export const CustomTextArea = (props) => {
  
}

export const CustomCheckBox = ({isChecked, onChange }) => {
  const [checked, setChecked] = useState(isChecked);

  const handleToggle = () => {
    setChecked(!checked);
    onChange(!checked); // Kutsuu onChange-funktiota ja välittää uuden tilan
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleToggle}
      />
      {/* <label>{label}</label> */}
    </div>
  );
};

export const CustomRadioButton = ({ options, name, onChange, value}) => {
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    const selected = value ? value : options[0]?.value;
    setSelectedValue(selected)
  },[])

  const handleChange = (value) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div style={{ display: 'flex'}}>
      {options.map((option, index) => (
        <label key={index} style={{ display: 'flex', alignItems: 'center', margin: '0px 10px 0px 10px'}}>
          <input
            type="radio"
            value={option.value}
            name={name}
            checked={selectedValue === option.value}
            onChange={() => handleChange(option.value)}
          />
          <span style={{ marginLeft: '8px' }}>{option.label}</span>
        </label>
      ))}
    </div>
  );
}

export const CustomPopUp = (props) => {
  const DIV = props.div || 'div';
  let content;
  if(typeof props.content === 'object'){
    content = (
      <>
        <p>{props.content.message}</p>
        <ButtonContainer>
        {props.content.buttons.map((button, index) => (
            <CustomButton key={index} label={button.label} onClick={button.onClick} button={button.bstyle ? button.bstyle : Styled20radiusButton}/>
        ))}
        </ButtonContainer>
      </>
    )
  }else{
    content = props.content.split('\n').map((line, index) => (
                          <>
                          {line}
                          <br />
                          </>
                        )
                    )};
  return(
    <Modal
        isOpen={props.isOpen}
        onClose={props.setIsOpen}
        children = {
          <DIV>
            {content}
          </DIV>
        }
    />
  )
}

export const ExpiryDate = (expiredTime) => {
  let dateNow = new Date(DateNow());
  dateNow.setDate(dateNow.getDate() + expiredTime);
  return dateNow;
}

export const MapPartName = (data) => {
  let part = data?.map((item, i) => {
      let partName = Object.values(item)[0][0]?.part_of_workout_name
    return <p key={Math.random()}>{partName}</p>
  })

  return part;
}

export const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleDarkModeChange = (e) => setIsDarkMode(e.matches);

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handleDarkModeChange);
    } else {
      // Vanhemmille selaimille
      mediaQuery.addListener(handleDarkModeChange);
    }

    // Poista event listener/tarkkailija komponentin purkautuessa
    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handleDarkModeChange);
      } else {
        // Vanhemmille selaimille
        mediaQuery.removeListener(handleDarkModeChange);
      }
    };
  }, []);

  return isDarkMode;
};

export const ShowStars = ({stars, theme}) => {
  const average = stars ? stars?.reduce((sum, star) => sum + star, 0) / stars.length : 0;

  const star = <MdOutlineStar size={20} color={theme.colors.star} />
  const starOutline = <MdOutlineStarOutline size={20} color={theme.colors.star} />
  let starList = new Array(5).fill(starOutline);
  for(let i = 0; i < Math.floor(average); i++){
      // lista.splice(i,i,star)
      starList[i] = star;
  }

  return starList.map((star, ind) => {
    return <div key={ind}>{star}</div>
  });
 } 
