import styled from 'styled-components';

export const CardListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  max-width:1300px;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr); /* 3 korttia rinnakkain */
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); /* 3 korttia rinnakkain */
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr); /* 2 korttia rinnakkain */
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr); /* 1 kortti per rivi */
  }
`;