

export const Colors = {

    darkMode: {
        BG_COLOR: '#000000',
        // TEXT_BG_COLOR: 'rgba(0, 0, 0, 0.6)',
        STAR_COLOR: 'rgb(255, 230, 128)',
        TEXT_BG_COLOR: 'rgba(127, 182, 213, 0.2)',
        TEXT_COLOR: 'rgba(255, 255, 255)',
        BUTTON_COLOR: 'rgb(38, 82, 124, 0.9)', //'#265956',
        BUTTON_HOVER: 'rgb(23, 54, 52, 0.8)',
        BORDER_COLOR: 'rgba(255, 255, 255, 0.9)',
        SHADOW_COLOR: 'rgba(255, 255, 255, 0.6)',
        POPUP_COLOR: 'rgba(255, 255, 255, 0.08)',
        DISABLED_TEXT: 'rgba(255, 255, 255, 0.356)'
    },
    lightMode: {
        // BG_COLOR: '#7fb6d5',//'#B3D7D5',
        // BG_COLOR: 'rgba(255, 255, 255)',
        BG_COLOR: 'rgb(236, 244, 249)',
        // TEXT_BG_COLOR: 'rgba(127, 182, 213, 0.7)',
        // TEXT_BG_COLOR: 'rgba(255, 255, 255, 0.5)',
        STAR_COLOR: 'rgb(255, 204, 0)',
        TEXT_BG_COLOR: 'rgba(127, 182, 213, 0.1)',
        TEXT_COLOR: 'rgba(0, 0, 0)',
        BUTTON_COLOR: 'rgb(38, 82, 124, 0.9)', //'#3ec9c2',
        BUTTON_HOVER: 'rgb(23, 54, 52, 0.8)',
        BORDER_COLOR: 'rgba(0, 0, 0, 0.9)',
        SHADOW_COLOR: 'rgba(0, 0, 0, 0.6)',
        POPUP_COLOR: 'rgba(0, 0, 0, 0.08)',
        DISABLED_TEXT: 'rgba(0, 0, 0, 0.356)'
    },
    global:{
        WARNING: '#741313'
    },
    calendar: {
        darkMode: {
            TILE:'#000',
            BORDER_COLOR: 'rgb(247, 245, 245, 0.6)',
            HOVER: 'rgb(247, 245, 245, 0.3)'
        },
        lightMode:{
            // TILE:'#7fb6d5',
            TILE: 'rgba(255, 255, 255)',
            BORDER_COLOR: 'rgba(0, 0, 0, 0.6)',
            HOVER: 'rgba(127, 182, 213, 0.2)'
        }
    },

    darkerBgColor: '#173634',
    navIcon: 'rgba(0, 0, 0, 0.3)',
    white: "#FFFFFF",
    black: "#000000",
    textColor: "#000000",
    transparentgBlue: 'rgb(179, 215, 213, 0.3)',
    button: '#3ec9c2',
    red: '#e5141e',
    btnContainerColor: '#A9CDCB',
    transparentBlack: 'rgba(0, 0, 0, 0.3)',
    darkBlue: 'rgb(8, 18, 10)',
    lightBlueTxt: 'rgb(237, 247, 246)',

}