import React, { useDebugValue, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/ClipLoader";

// Components
import { Dropzone } from "../Modify_workout/NewWorkout.jsx";
import { CustomPopUp, CustomCheckBox } from "../../../components/Components.jsx";
//utils
import { TextMinLenCount } from "../../../Utils/Utils.jsx";
// Style
import { Container, DescriptionContainer, ButtonContainer, PublishContainer } from "../../../components/style/Container.styled";
import { Row, Column, RowColumn } from "../../../components/style/Row.styled";
import { StyledSubmitButton, StyledAnalyticsButton } from "../../../components/style/Buttons.styled.jsx";
import { StyledTextArea, StyledCustomInput } from "../../../components/style/CustomInput.styled";
//Icon
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";
import { Trash } from "../../../components/style/icons.jsx";
//button
import { CustomButton, PublishButton } from "../../../components/Buttons.jsx";
//API
import { PublishExercise } from "../../../Utils/ApiRequests.jsx";



const PublishDetails = ({data, accessRole, theme, close}) => {
    const [newData, setNewData] = useState([]);
    const [partnames, setPartnames] = useState([]);
    const [expires, setExpires] = useState();
    const [price, setPrice] = useState();
    const [content, setContent] = useState("");
    const [showInfo, setShowInfo] = useState(false);
    const [termReaded, setTermReaded] = useState(false);
    const [showPart, setShowPart] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isFree, setIsFree] = useState(false);
    const [isUnlimited, setIsUnlimited] = useState(false);
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);

    const currency = "eur";
    const minDescLen = 50;

    useEffect(() => {
        data.description = "";
        data.media = null;
        setNewData(data);
    },[data])

    // jatkossa nämä tulee json/db jota voi muokata
    const description = "Write a description of the training program as descriptive as possible, a good description increases interest." +
                        " \n\n- Set a realistic price and tell how long the exercise will be available after purchase. " +
                        " \n\n- Also choose whether to display only the number of exercise parts or also the titles." +
                        " \n\nThe published material must not contain racist, prohibited or other offensive content." +
                        " Violation of the terms may result in the deletion of the account." +
                        " \n\nThe company charges 8%-17% + payment brokerage service provider's expenses per purchase, however, so that the minimum charge is €1." +
                        " There is no charge for free content, however," +
                        " The company has the right to add advertisements to free products." +
                        " \nWe reserve the right to make changes."

    const ConfirmationPopUp = (formData) => {
      const buttons = [
        // { label: 'Ei', onClick: handleCancel, color: Colors.red},
        { label: 'Yes', onClick: async () => await handleConfirm(formData)},
        { label: 'No', onClick: () => setIsPopUpOpen(false)}
      ];

      const message =`Do you want to publish the ${data.name} of the exercise for ${price}${returnCurrency(currency)} \n` + 
                        `${expires > 0 ? 'Expires in ' + expires + ' days': 'Unlimited period'}`

      setContent({message: message, buttons: buttons});
      setIsPopUpOpen(!isPopUpOpen);
    }

    const ShowTerms = () => {
        setShowInfo(!showInfo);
        setTermReaded(true);
    }

    const handleOnPublish = async () => {
        const checkLen = TextMinLenCount(newData?.description, minDescLen)

        if(!checkLen){
            toast.info("Please check the description.");
            return;
        }

        if(!newData?.file){
            toast.info("Please check the image.");
            return;
        }

        if(price < 5 && !isFree){
            setPrice(5);
            toast.info("The lower limit of the price is €5 or free");
            return;
        }

        if(expires < 14 && !isUnlimited){
            setExpires(14);
            toast.info("The minimum validity period is 14 days or unlimited");
            return;
        }

        const dataObj = {id: newData.id, description: newData?.description, price:price, expires:expires, showPart:showPart, partnames:partnames, currency: currency};
        const formData = new FormData();
        formData.append('data', JSON.stringify(dataObj)); // Lisätään muu data
        formData.append(`media`,newData.file);

        // custom popup
        ConfirmationPopUp(formData)

        // const visible = await GetWorkoutVisibility(id);
        // setVisibility(visible)

    }

    const handleConfirm = async (formData) => {
        setLoading(true);
        setIsPopUpOpen(false);

        await PublishExercise(formData);

        setLoading(false);
        toast.success("Published");
        close();
    }

    const returnCurrency = (currency) => {
        switch (currency) {
            case 'eur':
                return '€';
            case 'usd':
                return '$'
            default:
                return '€';
        }
    }

    const handleSetPrice = () => {

        const updatedPrice = price < 5 ? 5 : price;
        
        if (price < 5) {
          toast.info("The lower limit of the price is €5 or free");
        }
      
        setPrice(updatedPrice);
      };

    const handleSetExpirity = () => {

        const updatedExpirity = expires < 14 ? 14 : expires;
        
        if (expires < 5) {
          toast.info("The minimum validity period is 14 days or unlimited");
        }
      
        setPrice(updatedExpirity);
      };
    
    const handleCheckFree = (value) => {

        setIsFree(value)

        if(value){
            setPrice(0);
        }
    }

    const handleCheckUnlimited = (value) => {

        setIsUnlimited(value)

        if(value){
            setExpires(0);
        }
    }

    return(
        <Container background={theme.colors.body}>
            {!loading ?
            <PublishContainer>
            <Row>
                <Column>
                    <Row margin={'0 0 30px 0'} padding={'0'}>
                        <h3 style={{marginRight: 10}}>{data.name}</h3>
                        <IoInformationCircleOutline onClick={() => setShowInfo(!showInfo)}size={30} color={theme.colors.text}/>
                    </Row>
                    <Dropzone 
                        roleCheck={'beginner'}
                        content={newData}
                        setContent={setNewData}
                        placeholder={'Image*'}
                        accept={{
                            'image/*': ['.jpeg', '.jpg', '.png', '.gif']
                        }}/>
                </Column>
                <Column justify={'end'}>
                    <Row width={'fit-content'}>Free
                        <CustomCheckBox
                            isChecked={isFree}
                            onChange={isFree => handleCheckFree(isFree)}
                        />
                    </Row>
                    <Row width={'fit-content'}>
                        <p>{"Price: "}</p>
                        <StyledCustomInput
                            placeholder="10"
                            value={price} 
                            onChange={(e) => setPrice(e.target.value)}
                            onBlur={handleSetPrice}
                            text_decoration={'underline'} 
                            border={'none'} 
                            text_align={'center'} 
                            width={30}
                            margin={'0'}
                            disabled={isFree}/>
                        <p>{returnCurrency(currency)}</p>
                    </Row>
                    <Row width={'fit-content'}>Unlimited
                        <CustomCheckBox
                            isChecked={isUnlimited}
                            onChange={isUnlimited => handleCheckUnlimited(isUnlimited)}
                        />
                    </Row>
                    <Row width={'100%'}>
                        <p>{`Expires in `}</p>
                        <StyledCustomInput 
                            placeholder="30"
                            value={expires}
                            onChange={(e) => setExpires(e.target.value)}
                            onBlur={handleSetExpirity}
                            text_decoration={'underline'} 
                            border={'none'} 
                            text_align={'center'} 
                            width={30}
                            margin={'0'}
                            disabled={isUnlimited}/>
                        <p>{`days`}</p>
                    </Row>
                    <Row margin={'8px 0 0 0'}>
                        <p>{data?.list.length} {'exercises'}</p>
                    </Row>
                </Column>
            </Row>
            <Row>
                <Column width={'100%'}>
                    <h4 style={{marginBottom: 10}}>Workout content</h4>
                    <RowColumn padding={'6px'} justify={'start'} maxheight={'150px'} style={{background: theme.colors.textBg, borderRadius: 10}}>
                        {data?.list.map(list => {
                            const l = Object.values(list)[0]
                            const content = l.map(item => {
                                (item?.part_of_workout_name && (data.list.length > partnames.length) )&& (partnames.push(item.part_of_workout_name))
                                return (
                                    <div>
                                        {item?.part_of_workout_name && (
                                            <h5 style={{margin: '10px 0px 5px 0', textTransform: 'uppercase', color: !showPart && (theme.colors.disabledText)}}>{item.part_of_workout_name}</h5>
                                        )}
                                        {item?.name_of_exercise && (
                                            <p style={{color: theme.colors.disabledText}}>{item.name_of_exercise}</p>
                                        )}
                                    </div>
                                )
                            })

                            return(
                                <Column margin={'0 10px 0 0'} borderRadius={'10px'}>
                                    {content}
                                </Column> 
                            )
                        })}
                    </RowColumn>
                    <Row padding={'0'} margin={'10px 0 10px 0'} width={'fit-content'} onClick={() => setShowPart(!showPart)}>
                        {!showPart ? <MdOutlineCheckBoxOutlineBlank size={20}/> : <MdOutlineCheckBox size={20}/>}
                        <p>Display exercise parts title</p>
                    </Row>
                </Column>
            </Row>
            <Row>
                <Column width={'100%'}>
                    <h5>Description*</h5>
                    <StyledTextArea
                        value={newData.description} 
                        onChange={(e) => setNewData({...newData, description: e.target.value})}
                    />
                    <p style={{fontSize: 12, opacity: '70%'}}>{minDescLen} / {newData?.description?.length}</p>
                </Column>
            </Row>
            <ButtonContainer style={{padding: '15px 10px 0px 10px'}}>
                <Row padding={'0'} width={'fit-content'} onClick={() => ShowTerms()}>
                    {!termReaded ? <MdOutlineCheckBoxOutlineBlank size={30}/> : <MdOutlineCheckBox size={30}/>}
                    <p>Accept the terms</p>
                </Row>
                <Row width={'fit-content'}>
                <CustomButton
                    button={StyledSubmitButton}
                    onClick={() => handleOnPublish()}
                    label={"Publish"}
                    style={{width: '100px'}} 
                    disabled={!termReaded}/>
                <CustomButton
                    button={StyledAnalyticsButton}
                    icon={<Trash />}
                    onClick={() => close()}
                />
                </Row>
            </ButtonContainer>
            </PublishContainer>
            :
            <ClipLoader
                color={theme.colors.button}
                loading={loading}
                // cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            }
          <CustomPopUp content={description} isOpen ={showInfo} setIsOpen={() => ShowTerms()} div={DescriptionContainer}/>
          <CustomPopUp content = {content} isOpen={isPopUpOpen} setIsOpen={() => close()} div={DescriptionContainer}/>
        </Container>
    )
}

export default PublishDetails;