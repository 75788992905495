
export const checkPwdIsSame = (password, password2) =>{
    if(password !== password2){
        console.info("passwords do not match!");
        return false;
    }else{
        return true;
    }
}

export const checkPwdLen = (password) => {
    const pwdLen = 10;
    if(password.length > 3){
        if(password.length < pwdLen){
            console.info("Salasana pitää olla vähintään 10 merkkiä");
            return false;
        }else{
            return true;
        }
    }else{
        return false;
    }


}   

export const isPwdStrong = (password) => {
    if(password){
     const hasNumber = /\d/.test(password);
     const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);
    
     // Palauta tulos, joka osoittaa, täyttääkö salasana vaatimukset
     return hasNumber && hasSpecialCharacter;
    }else{
     return true;
    }
 }