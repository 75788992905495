import styled from 'styled-components';


export const SquareImage = styled.div`
    width: ${({width}) => width ? width : '50px'};
    height: ${({height}) => height ? height : '50px'};
    min-width: ${({width}) => width ? width : '50px'};
    min-height: ${({height}) => height ? height : '50px'}; 
    background-image: url(${(props) => props.src});
    background-size: contain; /* Säädä kuvan kokoa */
    background-repeat: no-repeat; /* Estää kuvaa toistumasta */
    background-position: left center;
    border: solid ${({theme}) => theme.colors.border};
    border-radius: 5px;
`