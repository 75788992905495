import React, {useState, useEffect} from "react";
import { useLocation, useNavigate} from 'react-router-dom';

//Component
import { ExerciseContainer } from "../StyledComponents";
import { WorkoutMedia } from "../WorkoutMedia";
import { useData } from "../../../Utils/UseContext.jsx";
import { createBlobUrl } from "../../../Utils/Utils.jsx";
import { Row } from "../../../components/style/Row.styled.jsx";
import { StyledVideoContainer } from "../../../components/style/Container.styled.jsx";


const Media = ({item}) => {
    const [duration, setDuration] = useState(null);

      // Tarkistetaan, onko data Base64-formaatissa
  if (!item?.file || !item?.file.startsWith('data:video/')) return null;

  const videoUrl = createBlobUrl(item?.file)

    return(
        <>
        <video controls  
                style={{ 
                maxWidth: '100%', 
                maxHeight: '100%', 
                width: '100%', 
                height: 'auto',
                objectFit: 'contain' // Tämä varmistaa, että video ei veny suhteettomasti
            }}>
            <source src={videoUrl} type={item?.media_type} />
            Your browser does not support the video tag.
        </video>
        {duration !== null && <p>Videon kesto: {duration.toFixed(2)} sekuntia</p>}
        </>
    )
}

const MyVideo = ({theme}) =>{
    const location = useLocation();
    const {personalVideo, setPersonalVideo} = useData();
    const {id} = location.state || {};

    const data = personalVideo?.videos.find(item => item.workout_id === id);

    if(!data){
        return(
            <p>No data</p>
        )
    }

    return(
        <ExerciseContainer justify={'none'}>
            <h3>{data?.workout_name}</h3>
            <StyledVideoContainer>
                <Media item={data}/>
                <div>
                    <h4>Description</h4>
                    <p>{data?.description}</p>
                </div>
            </StyledVideoContainer>

        </ExerciseContainer>
    )
}

export default MyVideo;