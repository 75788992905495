import React, {useEffect} from 'react';
//icon
import { MdAdd } from "react-icons/md";
import { FaCircleStop, FaPlay } from "react-icons/fa6";

import { StyledExitButton, StyledTimerButton, StyledSubmitButton, StyledPublishButton, StyledAddButton } from './style/Buttons.styled.jsx';
//constants
import {ADMIN, BASIC, IDENTIFIED, OWNER} from '../CONSTANTS.js'
import { HasRole } from '../Utils/Role.jsx';

export const CustomButton = (props) => {
    const BUTTON = props.button || 'button';
    const disabled = props.disabled || false;
    const type = props.type || 'button';
    return(
        <BUTTON 
            type={type}
            disabled={disabled}
            style={props.style}
            onClick={props.onClick}>
            {props.icon}
            {props.label}
        </BUTTON>
    )
}

export const CustomButtonGroup = ({options}) => {
    
    return(
        <>
            {options.map((option, index) => 
                <option.button 
                    key={index}
                    type={'button'}
                    style={option.style}
                    onClick={option.onClick}>
                    <span style={option?.style?.iconStyle}className='icon'>{option.icon}</span>
                    <p style={option?.style?.labelStyle}>{option.label}</p>
                </option.button>
            )}
        </>
    )
}

export const ExitButton = ({onClick, color, label}) => {
    return(
        <StyledExitButton
            style={{ zIndex:1}}
            onClick={onClick}>
                {label}
        </StyledExitButton>
    )
}

export const TimerButton = (props) => {
    const isTimer = props.isTimer ? true : false;
    return (
        <StyledTimerButton
            onClick={() => props.handleTimer()}>
            <p>{!isTimer ? <FaPlay size={30}/> : <FaCircleStop size={30}/>}</p>
        </StyledTimerButton>
    )
}

export const SubmitButton = ({width, label, disabled}) => {

    return(
        <StyledSubmitButton
            width={width}
            type="submit" 
            disabled={disabled}>
                {label}
        </StyledSubmitButton>
    )
}

export const PublishButton = ({accessRole, onClick, isPublished, icon, disabled}) => {
    const accessRight = accessRole;

      if(accessRight !== OWNER){
          return null;
      }

    return(
        <StyledPublishButton
            onClick={onClick}
            disabled={disabled}
        >
            {icon}
            {isPublished ? 'unpublish' : 'publish'}
        </StyledPublishButton>
    )
}

export const AddButton = ({onClick}) => {
    
    return(
        <StyledAddButton onClick={onClick}>
            <MdAdd size={40}/>
        </StyledAddButton>
    )
}