import React, {useEffect, useState, useRef} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
//API
import { GetWorkoutEvents, DeleteEvent } from "../../Utils/ApiRequests";
//style
import {CustomCalendar} from '../../components/style/Date.styled';
import { CalendarInfoContainer } from "../../components/style/Container.styled";
import { SquareImgontainer, RightBottomFloatingContainer } from "../../components/style/Container.styled";
import { Column, Row } from "../../components/style/Row.styled";
//Components
import { AddButton } from "../../components/Buttons";
//Image
import Logo from '../../components/style/images/Logo.png'
// icons
import { IoTrash, IoCloudOffline } from "react-icons/io5";
//utils
import { TimeToString } from "../../Utils/Utils";


const InfoContainer = ({workoutData, setWorkoutData, activeDate}) => {
    const selectedDate = moment(activeDate).format('YYYY-MM-DD');
    const [workouts, setWorkouts] = useState(workoutData?.filter(w => moment(w.date_time).format('YYYY-MM-DD') === selectedDate));
    const navigate = useNavigate();
    //let workouts = workoutData?.filter(w => moment(w.date_time).format('YYYY-MM-DD') === selectedDate)

    useEffect(() => {
        let workout = workoutData?.filter(w => moment(w.date_time).format('YYYY-MM-DD') === selectedDate)

        setWorkouts(workout)
    },[selectedDate, workoutData]);
   

    const handleOnDelete = async (id) => {
        const newList = workoutData.filter(item => item.calendar_id !== id);
        await DeleteEvent(id);
       
        setWorkoutData(newList)

    }
  
    return(
        <CalendarInfoContainer>
                {(workouts && workouts.length > 0) && (
                    workouts.map((workout, index) => {
                        const startTime = TimeToString(new Date(workout?.date_time));
                        // moment(workout?.date_time).format('HH.mm')
                        const name = workout?.name_of_exercise;
                        const part_name = workout?.workout_part_name;
                        const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
                        const capitalizedPartName = part_name.charAt(0).toUpperCase() + part_name.slice(1);
                        const values = workout?.workout_value;
                        const id = workout?.part_of_workout_id;
                      
                        return(
                            <div key={index} style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', width: '100%', marginBottom: 10, cursor: 'pointer'}}>
                                <Row justify={'flex-start'} onClick={() => {id !== null && (navigate(`/workout/my/${id}`, { state: { name, values, id }}))}} style={{cursor: 'pointer'}}>
                                    <SquareImgontainer src ={Logo}/>
                                    <div style={{marginLeft: 20}}>
                                        <h4>{capitalizedPartName}</h4>
                                        {name !== 'rest' && (<p>Starts at {startTime}</p>)}
                                    </div>
                                </Row>
                                <Column>
                                    <IoTrash onClick={() => handleOnDelete(workout.calendar_id)} size={20} style={{cursor: 'pointer'}}/>  
                                </Column>
                            </div>
                        )
                    })
                )}
        </CalendarInfoContainer>
    )
}

const Calendar = ({theme}) => {
    const navigate = useNavigate();
    const location = useLocation();
    // const {date, exercise, keyValuePair, fromSaveButton} = location.state || {};
    const [currentDate, setCurrentDate] = useState(new Date());
    const [activeDate, setActiveDate] = useState(currentDate);
    const [workoutData, setWorkoutData] = useState([]);

    const fetchEvents = async () => {
        // Hae eventit tietokannasta
         const fetchedEvents = await GetWorkoutEvents(); // Oikea endpoint ja hakulogiikka
         
         setWorkoutData(fetchedEvents);
      };
   

     useEffect(() => {

         if (location.state?.from === 'workout_event') {
             fetchEvents();
           }
     },[location.state])

     useEffect(() => {
         if (!location.state) {
             fetchEvents();
         }
       }, []);

    const handleDateChange = (date) => {
        setActiveDate(date); // Set selected date to active
    };
 
    return(
        <>
            <CustomCalendar
                onChange={handleDateChange}
                value={activeDate}
                theme={theme}
            />
            <InfoContainer workoutData={workoutData} setWorkoutData={setWorkoutData} activeDate={activeDate}/>
            <RightBottomFloatingContainer>
                <AddButton onClick={() => navigate('/workout/planer')}/>
            </RightBottomFloatingContainer>
        </>
    )
}

export default Calendar;